.chip_edit{
margin-right: 20px !important;
background-color: var(--green) !important;
color: white !important;
padding: 10px !important;
cursor: pointer !important;
}


.chip_cancel{
  background-color: darkgray!important;
  color: white !important;
  padding: 10px !important;
  cursor: pointer !important;

}
.debited{
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    color: white;
    text-transform: uppercase;
    padding: 5px;
    background-color: red;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-radius: 20px;
}
.box_transactions{
  min-height:400px !important;
  width: 75% !important;
  /* padding:20px !important; */
}
@media (max-width:500px) {
  .box_transactions{
    width: 98% !important;
    /* padding:20px !important; */
  }
  
}