.below_home_icon{
    height: 150px;
    padding-top: 5vh;
    padding-bottom: 1vh;
}
.below_home_head{
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    color: var(--green);
}
.below_home_text{
    padding-top: 1vh;
    padding-bottom: 2vh;
}
.below_home_map{
    background: linear-gradient(rgba(118, 187, 64,0.3),rgba(118, 187, 64,0.3)),url('../../../images/bg_map.jpg');
    background-repeat: no-repeat;
    height: 60vh;
    width: 100%;
    background-size: cover;
}
.below_home_map_phone{
    height: 50vh;
}

.below_home_map_head{
    font-size: 4rem !important;
    padding-bottom: 2vh;
}
.below_home_map_text{
    font-size: 1.4rem !important;
    padding-bottom: 2vh;
}
@media (max-width:500px) {
    .below_home_map_phone{
       display: none;
    }
    .below_home_map_head {
        font-size: 3rem !important;
        padding-bottom: 2vh;
    }
    
}