.footer_1{
    margin: 20px 0px !important;
    border-right: .5px solid black;
    height: 100%;
    min-height: 300px;
    padding: 20px !important;
    height: auto;
}
.footer_2{
    margin: 20px 0px !important;
    height: 100%;
    min-height: 300px;
    padding: 20px !important;
    height: auto;
}
.footer_head{
    font-size:22px !important;
    font-weight: 600 !important;
    color: var(--green);
    padding: 2vh 0px;
    text-align: justify;


}
.footer_head2{
    font-size:19px !important;
    font-weight: 600 !important;
    padding: 2vh 0px;
    text-align: justify;
}
.footer_text{
    font-size:19px !important;
    padding: 1vh 0px;
    text-align: justify;
    cursor: pointer;

}


.circle-icon1 {
    /*  background: rgb(53; 61; 66);   */
      background: transparent;
      border-radius: 50%;
      text-align: center; 
      line-height: 10px;
      padding: 15px 13px;
      vertical-align: middle;
      border: 2.6px solid var(--green);
      transition: .3sec;
      color: var(--green);
      margin-right: 1vw;
      font-size: 17px;
      text-decoration: none
  }

  .circle-icon1:hover {
    transform: scale(1.2);
    transition: .5s;
    /* background-color: var(--bcolor); */
    background-color:white;
    border-color: white;
    color: var(--green);
}
