.job_head{
    margin-top: 12% !important;
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    color: var(--green);}
    .job_company{
        font-size: 1.1rem !important;
        font-weight: 500 !important;
        /* margin-top: 2vh !important; */

    }
    .job_location{
        font-size: 1rem !important;
        font-weight: 500 !important;
        color: lightslategrey;
        padding-top: 5px;
    }
    .job_details{
        /* margin-top: 12%; */
        font-size: 1.3rem !important;
        font-weight: 600 !important;
        padding-top: 3vh
       ;}
       .job_salary{
        font-size: .8rem !important;
        font-weight: 400 !important;
        background-color:rgba(118, 187, 64,0.3) !important;
        padding: 7px;
        border-radius: 5px;
        width: fit-content;
        margin-top: 2vh !important;

    }
    .cont_job_details{
        margin-top: 3vh !important;
    }
    .circle-icon12 {
        background: transparent;
        border-radius: 50%;
        text-align: center;
        line-height: 10px;
        padding: 12px 10px;
        vertical-align: middle;
        border: 2.6px solid var(--green);
        transition: .3sec;
        color: var(--green);
        margin-right: 1vw;
        margin-top: 1vw;

cursor: pointer;
        font-size: 17px;
        text-decoration: none;
    }
    .circle-icon12:hover {
        transform: scale(1.2);
        transition: .5s;
        /* background-color: var(--bcolor); */
        background-color:var(--green);
        border-color: white;
        color: var(--white);
    }
    .job_description{
        font-size: 1rem !important;
        padding-top: 5px;
    }
    @media (max-width:500px) {
        .job_head{
            margin-top: 25% !important;
            font-size: 1.8rem !important;
            font-weight: 600 !important;
            color: var(--green);}
    }
    .circle-icon12 {
       margin-right: 5px !important;
    }