.HIW_bg{
    height: 90vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
      z-index:-1;
  display:block;

}
.HIW_bg_maincont{
    position: absolute;
    top: 20%;
    padding-left: 10vw;
}
.HIW_bg_head{
    font-size: 4.2rem !important;
    font-weight: 600 !important;
    color: white;
    padding-bottom: 2vh;

}
.HIW_bg_text{
font-size: 1.5rem !important;
    font-weight: 400 !important;
    color: white;
    padding-bottom: 2vh;
}
.HIW_bg_app{
    height: 50px;
    cursor: pointer;
}
.HIW_bg_paper{
        min-height: 50vh !important;
    max-width: 305px;
    border-radius: 20px !important;
}
.HIW_bg_inner{
        padding: 30px;

}
.HIW_bg_ppr_head{
font-size: 2rem !important;
    padding-bottom: 2vh;
}
.HIW_bg_ppr_text{
    font-size: 1rem !important;
    padding-bottom: 1.5vh;
}
.HIW_bg_ppr_works{
    font-size: 1.1rem !important;
    text-decoration-line: underline;
    padding-bottom: 1vh;
    text-decoration-color: var(--green);
    font-weight: 600 !important;
}
.HIW_bg_btn{
width: 60%;
    background: var(--green) !important;
    color: white !important;
    font-size: 16px !important;
}

.HIW {
  position:absolute;
  width:100%;
   height:90%;
    background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.9));}


  .HIW_bg_img{
    height: 60vh;
  }  
  @media (max-width:500px) {

    .HIW_bg_head {
        font-size: 3rem !important;
        
    }
    .HIW_bg_text {
        font-size: 1.33rem !important;
       
    }
    .HIW_bg_img {
       display: none;
    }
    .HIW_bg_maincont {
        padding:0px 4vw;
    }
  }