.pricing_head{
    font-size: 2rem !important;
    font-weight: bold !important;
    color: var(--green);
    margin-top: 15vh !important;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 2vh;
}
.pricing_ppr{
    width: 58%;
    /* padding: 15px; */
    margin: 10px;
    min-height: 100px;
    border-radius: 20px !important;
    border: 1px solid var(--green);
}
.pricing_plan_head{
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: var(--green);
    text-align: center;
    text-transform: uppercase;
    padding-top: 1vh;
    padding-bottom: 1vh;
}
.pricing_plan_btn{
    background: var(--green) !important;
    color: var(--white) !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    margin-top: 1vh !important;
    padding: 5px 10px !important;
    margin-bottom: 2vh !important;
    border: 1px solid var(--green) !important;


}
.pricing_plan_btn2{
    background: black !important;
    color: var(--white) !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    margin-top: 1vh !important;
    padding: 5px 10px !important;
    margin-left: 1vw !important;
    margin-bottom: 2vh !important;

}
.pricing_plan_btn:hover{
    background: var(--white) !important;
    border: 1px solid var(--green) !important;
    color: var(--green) !important;
    /* margin-top: 1vh; */

}
.pricing_plan_detail{
    line-height: 1.9 !important;
}
.headP{
    text-align: start;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    color: var(--green);
}
.sub_headP{
    text-align: left;
    font-size: 14px !important;
}
.price_p{
    font-size: 2.3rem !important;
    padding-top: 2vh;
    font-weight: 700 !important;
    text-align: left;
    color: var(--green);
}
.sub_price_p{
    text-align: left;
    font-size: 14px !important;
    line-height: 0.5 !important;
}
.details_p{
    text-align: left;
    font-size: 17px !important;
    /* font-weight: 500 !important; */
    line-height: 2 !important;
}
.pricing_plan_btn_new {
    background: var(--green) !important;
    color: var(--white) !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    margin-top: 2vh !important;
    /* margin-bottom: 2vh !important; */
    padding: 7px 13px !important;
    margin-bottom: 2vh !important;
    border: 1px solid var(--green) !important;
}

.pricing_ppr:hover{
    background-color: var(--green);
}
.pricing_ppr :hover .headP{
    color: white !important;
}
.pricing_ppr :hover .sub_headP{
    color: white !important;
}
.pricing_ppr :hover .sub_price_p{
    color: white !important;
}
.pricing_ppr :hover .details_p{
    color: white !important;
}
.pricing_ppr :hover .pricing_plan_btn_new{
    color: var(--green) !important;
    background: var(--white) !important;

}
.pricing_ppr :hover .price_p{
    color: white !important;
}
@media (max-width:500px) {
    .pricing_ppr{
        width: 75%;
        /* padding: 15px; */
        margin: 10px;
        min-height: 100px;
        border-radius: 20px !important;
        border: 1px solid var(--green);
    }
    
}