.ppr_today{
    background: rgba(118, 187, 64,0.3) !important;
    width: 100% !important;
    padding: 20px !important;
    border-radius: 20px !important;
    cursor: pointer !important;
}
.head_today{
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    text-align: start !important;
}
.ppr_sub_today{
background: var(--green) !important;
border-radius: 20px !important;
cursor: pointer !important;
color: white !important;

}
.ppr_sub_today_active{
    background: white !important;
    border-radius: 20px !important;
    cursor: pointer !important;
    color: var(--green) !important;
    border-color: var(--green) !important;
    
    }
.head_sub_today{
    font-size: 1.1rem !important;

padding: 4vh 0px !important;
/* color: white !important; */
}
.head_download{
    height:100% !important;
    display:grid !important;
    align-content:center !important;
    font-size: 1.2rem !important;
    color: white !important;
    font-weight: 500 !important;
    min-height: 200px;
}
.head_sub_today_green{
    background: var(--green) !important;
    border-radius: 20px !important;
    cursor: pointer !important;
    color: white !important;

    
    }
    .head_sub_today_grey{
        background: grey !important;
        border-radius: 20px !important;
        cursor: pointer !important;
        color: white !important;

        
        }
        .head_sub_today_red{
            background: red !important;
            border-radius: 20px !important;
            cursor: pointer !important;
            color: white !important;

            
            }
            @media (max-width:500px) {
                .head_download{
                    margin-top: 2vh !important;
                    min-height: 200px;
                }
                .ppr_today {
                    padding: 10px !important;
                }
            }