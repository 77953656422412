.contact_bg{
    background: linear-gradient(45deg,rgba(0,0,0,0.5),transparent),url('../../images/contact_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 550px;
}
.contact_head{
    text-align: center;
    font-size: 22px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--green);
}
.contact_subhead{
    font-size: 28px !important;
    color: var(--green);
    font-weight: 600 !important;
    text-align: center;
    padding-bottom: 20px;

}
.btn_contact{
    width: 95% !important;
    font-weight: 600 !important;
    background: var(--green) !important;

}
.iconC{
    font-size: 45px;
    color: var(--green);
    padding-bottom: 5px;

}
.iconC_head{
    font-size: 25px !important;
    color: var(--blackColor);
    font-weight: 600 !important;
    padding-bottom: 5px;
}
.iconC_text{
    font-size: 18px !important;
    color: var(--blackColor);
}
.ContactUs_Map {
    width: 100%;
    height: 390px;
}

.ppr_support{
    background: var(--green) !important;
    height: 150px;
    width: 300px;
    margin: 2vh 0px;
    padding: 10px;
    display: grid;
align-content: center;
cursor: pointer;
}
.ppr_support_text{
    color: white;
    height: 100%;
    font-size: 25px !important;
    font-weight: 600 !important;
}
.icon_tech{
padding-left: 5px;
}
@media (max-width:500px) {
    .contact_bg {
        height: 500px;
        background-position: right;
    }
}
