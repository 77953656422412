.HIW_below_text{
    margin-top: 5vh !important;
    padding-bottom: 5vh;
    font-size: 1.4rem !important;
    border-bottom: 2px solid var(--green);
    margin-bottom: 2vh !important;
}

.HIW_below_Works_head{
    font-size: 1.7rem !important;
    font-weight: 600 !important;
    color: var(--green);
    padding-bottom: 2vh;
    text-align: center;
}
.HIW_below_Works_img{
height: 110px;
}
.HIW_below_Works_head1{
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    color:black;
    padding-bottom: 1vh;
    padding-top: 2vh;

}
.HIW_below_Works_text{
    font-size: 1.2rem !important;
    color:black;
    padding-bottom: 2vh;
}
.below_home_FB_img{
    height: 250px;

}
.below_home_FB_head{
    font-size: 2.1rem !important;
    padding-bottom: 2vh;
  
    color: var(--green);
}
.below_home_FB_text{
    font-size: 1.4rem !important;

}
@media (max-width:500px) {
.below_home_FB_img {
    width: 100%;
}}