.field_search{
    padding-top: 5vh !important;
    width: 90%;
   

}
.btn_new{
    margin-top: 5vh !important;
    background-color: var(--green) !important;
    padding: 10px !important;
    
}
.btn_evd2{
    background-color: var(--green) !important;
    border-radius: 20px !important;    
    padding: 5px 22px !important;    color: white !important;

}
.btn_evd2:disabled{
  background-color: lightgrey !important;
}
.btn_evd3{
    background-color: red !important;
    border-radius: 20px !important;    
    padding: 5px 22px !important;   
     color: white !important;
  
  }
  .btn_evd3:disabled{
    background-color: lightgrey !important;
  }
  .btn_evd4{
    background-color: grey !important;
    border-radius: 20px !important;    
    padding: 5px 22px !important;   
     color: white !important;
  
  }
  .btn_evd5{
    background-color: silver !important;
    border-radius: 20px !important;    
    padding: 5px 22px !important;   
     color: white !important;
  
  }
  .icon_check{
    color: green !important;
  }
  .icon_check1{
    color: red !important;
  }
.MuiDataGrid-cellContent{
 
    justify-content: start  !important;
    display: grid !important;
    }
    .MuiDataGrid-cell--textLeft{
      /* justify-content: center !important; */
    }
    .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;}
.txt_info{
    padding: 1vh 0px;
}
.icon_img_dash{
  color: var(--green) !important;
  font-size: 17px;
  
}


