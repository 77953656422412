.job_post_head{
    margin-top: 15vh !important;
    font-size: 2rem !important;
    padding-bottom: 2vh !important;
    color: var(--green);
    text-align: center;
    font-weight: 500 !important;
}
.completed{
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  color: white;
  text-transform: uppercase;
  padding: 5px;
  background-color: rgba(118, 187, 64);
  max-width: fit-content;
  border-radius: 20px;
}
.job_post_head_section{
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    color: var(--green);
}
.job_post_textfield{
    width: 90%;
    margin: 1vh 0px !important;
}

.cont_search{
  /* background-color: whitesmoke; */
  text-align-last: left;
  justify-content: start;
  width: 100%  !important;
  padding: 10px;
  
  border-radius: 5
  px;
  cursor: pointer;
  }
  .main_txt{
    font-size: 17px !important;
    font-weight: 500 !important;
    width: 100%;
    text-align: left;
    padding: 10px !important
    }
    .sub_txt{
        font-size: 15px !important;
        font-style: italic;
        width: 100%;
        text-align: left;
    border-bottom: 1px solid var(--green);
    /* padding-bottom: 5px; */
    padding: 0px 10px 5px 10px !important
    
    
    }
.filter_values{
    width: 200px;
    /* height: 100px; */
    position: relative;
    margin-top: 8px;
    border-radius: 10px;
    background: #ffff;
    border: 1px solid var(--green);

  }
  .filter_values ul{
padding: 10px;
margin: 0px;

    max-height: 200px;
    overflow: scroll;
  }
  .list_filter{
    list-style: none;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--green);
    cursor: pointer;

    border-bottom: 1px solid var(--green);
  }
  .list_filter:hover{
    background:#eee ;
  }

  .chip_drop{
    background: var(--green) !important;
    color: white !important;
    font-size: 18px !important;
    padding: 15px 10px !important;
    /* border: 1px solid var(--pinkColor) !important; */
  }
  .job_post_chipp_add{
    background-color: var(--green) !important;
    color: white !important;
    margin-right: 15px !important;
  }
  .job_post_chipp_delete{
    background-color: black !important;
    color: white !important;
  }
  .submit_job{
    background: var(--green) !important;
    color: white !important;
    font-size: 18px !important;
    padding: 15px 10px !important;
    margin:2vh 0px !important;
    text-transform: uppercase;
  }
  .btn_no{
    background: darkgrey !important;
    color: white !important;
    margin: 5px !important;  }

.btn_yes{
  background: var(--green) !important;
  color: white !important;
  margin: 5px !important;
}

.Dialog_CButton .MuiPaper-root{
  margin:0px;
}
.Dialog_CButton .MuiDialogContent-root{
  width: 360;
        height:360;
        text-align: center;
}

.Dialog_CButton .MuiBackdrop-root{
  background: rgba(0,0,0,0.44);

}