/* @import "../../MainCSS.css"; */


.A_Form2_Container {
      text-align: left;
      padding-bottom: 20px;
      padding-left: 0px  !important;
      padding-right: 0px !important;
    }
.A_Form2_Cont_Paper {
        width: 100%;
        width: 360px;
     /*   height: 320px; */
}
.A_Form2_Div{
    background-color: white;
  /*  width: 300px; */
    height: auto;
    padding-top: 5px;
}
.AForm_Div_P2 {
    font-size: 15px;
  }

.AForm_HDiv1 {
    height: 30px;
    width: 335px;
    border-bottom: 1px solid var(--blueColor);
    margin-bottom: 5px;
  }
  
  .AForm_Div_H1 {
    font-size: 22px;
    color: var(--blueColor);
  }
  .AForm_PDiv1 {
    margin: 0px 0px;
    margin-top: 4px;
  /*  margin-bottom: 15px;  */
  }
  .TF_div {
    /* height: 50px; */
    margin-top: 10px;
  }
  .TF_Label {
    font-size: 15px;
    font-weight: 400;
  }
.custom-form-input{
  width: 100%;
}
.A_Form2_Div .MuiInput-underline:before {
    border-bottom: 1px solid var(--blueColor) !important;
    border-bottom: none !important;
}
.A_Form2_Div .MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid rgb(146, 22, 22) !important;
    border-bottom: none !important;
}
.A_Form2_Div .MuiInput-underline:after {
    border-bottom: 1px solid var(--blueColor) !important;
    border-bottom: none !important;
}
.A_Form2_Div .MuiInputBase-input {
    color: #2f2f2f !important;
    padding: 3px 10px !important;
    font-size: 15px !important;
    font-weight:normal !important;
    padding-bottom: 6px !important;
    /* border: 1px solid #d8d8d8; */
   border-radius: 5px;
}
.A_Form2_Div .MuiInputBase-root {
  width: 100% !important;
}
.A_Form2_Div .MuiFormLabel-root {
    color: var(--blueColor)  !important;
    font-size:15px !important;
    font-weight:600 !important;
}
.A_Form2_Div .Button_UL {
  margin-top: 50px;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 5px;
}
.A_Form2_Div .MuiButton-containedPrimary {
    font-size: 16px !important;
 /*   width: 120px !important; */
    height: 40px !important; 
    border: 1px solid var(--green) !important;
/*    border-radius: 4px !important; */
    margin-top: 10px !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    color: white !important;
    background-color: var(--green) !important;
    min-width: 150px;
}
.icon-close-btn21{
  left: 92%;
  color: var(--blueColor);
}
.A_Form2_Div .MuiFormHelperText-root{
    margin-top: 5px !important;
    line-height: 0 !important;
    margin-left: 0px;
    font-size: 11px;
}
.A_Form2_Div .MuiFormControl-root{
  width: 100% !important;
}
.A_Form2_Div .MuiButton-contained.Mui-disabled {
    font-size: 15px !important;
}
.A_Form2_Div .MuiButton-containedPrimary:hover {
    background-color: white !important;
    color: var(--blueColor) !important;
}
.D6_DASAC_CSA_DateDiv {
  margin-top: 9px;
}
.D6_DASAC_CSA_DateDiv .MuiFormLabel-root{
  font-size: 18px !important;
}
.D6_DASAC_CSA_DateDiv .MuiInputBase-root{
  width: 310px !important;
}
.D6_DASAC_CSA_DateDiv .MuiFormControl-marginNormal {
    margin-top: 0px !important;
}
.D6_DASAC_CSA_DateDiv .MuiIconButton-label {
  margin-top: -30px;
  margin-left: -30px;
}
.PNDiv_SA {
    /* height: 55px; */
    margin-top: 10px;
  }

  .PNDiv_SA .PN_Label2 {
      font-size: 15px;
      padding-bottom: 10px;
      font-weight: 400;
  }
  .PNDiv_SA .PN_Input2 {
    /* height: 20px; */
    background-color: rgb(0,0,0,0);
    margin-bottom: 5px;
    /* color: #000000; */
  }
  .D6_DASAC_CSA_DateDiv .PN_Input21 {
    /* height: 20px; */
    background-color: rgb(0,0,0,0);
    margin-bottom: 5px;
    /* color: #000000; */
  }
  
  .PNDiv_SA .react-tel-input {
    font-size: 14px !important;
  }
  
  .PNDiv_SA .react-tel-input .form-control {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    height: 30px !important;
    position: relative;
    letter-spacing: .01rem;
   /* border-radius: 5px !important; */
    border-radius: 5px !important; 
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    font-size: 15px !important;
    color: var(--blueColor) !important;
    background-color:white !important;
    border:1px solid #d8d8d8 !important;
  }
  .PNDiv_SA .react-tel-input .flag-dropdown {
      background-color: white !important;
      border: 1px solid #d8d8d8 !important;
      border-radius: 5px 0 0 5px !important;
  }

  
  @media (max-width: 490px) {
    .A_Form2_Cont_Paper {
      width: 280px;
    }
    .A_Form2_Container {
      width:auto;
    }
    .AForm_HDiv1 {
      width: 255px;
    }
    .AForm_Div_H1 {
      font-size: 19px;
    }
    .D6_DASAC_CSA_DateDiv .MuiInputBase-root{
      width: 255px !important;
    }
    .PNDiv_SA .react-tel-input .form-control {
      width: 89% !important;
    }
    .A_Form2_Div .MuiInputBase-root {
      width: 255px !important;
    }
    .A_Form2_Div .Button_UL {
      margin-top: 40px;
      width: 255px;
      border-bottom: 1px solid var(--blueColor);
      margin-bottom: 5px;
    }
} 
